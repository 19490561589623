<template>
  <div class="">
    <baidu-map class="map" :center="{ lng: 116.404, lat: 39.915 }" :zoom="11">
      <bm-driving
          :start="start"
          :end="end"
          @searchcomplete="handleSearchComplete"
          :panel="false"
          @stop="reset"
          :autoViewport="true"
      ></bm-driving>
      <bml-lushu :path="path" :icon="icon" :play="play" :rotation="true">
      </bml-lushu>
    </baidu-map>
    <input ref="start" placeholder="请输入起点" value="汉字过后" />
    <input ref="end" placeholder="请输入终点" value="百度大厦" />
    <button type="button" @click="confirm">确认</button>
  </div>
</template>

<script>
import { BmlLushu } from "vue-baidu-map";
export default {
  computed: {},
  components: {
    BmlLushu,
  },
  data() {
    return {
      play: false,
      path: [],
      icon: {
        url: "http://api.map.baidu.com/library/LuShu/1.2/examples/car.png",
        size: {
          width: 52,
          height: 26,
        },
        opts: {
          anchor: {
            width: 27,
            height: 13,
          },
        },
      },
      start: "河池市宜州区",
      end: "河池市都安县",
    };
  },
  methods: {
    reset() {
      this.play = false;
    },
    handleSearchComplete(res) {
      this.path = res.getPlan(0).getRoute(0).getPath();
    },
    confirm() {
      this.start = this.$refs.start.value;
      this.end = this.$refs.end.value;
      this.play = true;
    },
  },
};
</script>

<style scoped>
.map {
  height: calc(100vh - 100px);
  width: 100%;
}
</style>
